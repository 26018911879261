import { QueryConfig, useQuery } from 'react-query';
import { QUERY_CACHE_MANAGER_INFO } from '@/shared/constants/api';
import { getManagerInfo } from '@/shared/services/manager';
import { useDispatch } from 'react-redux';
import { useAppProperties } from '@/shared/hooks/app';
import { PreferredLoginEnum, UserRolesTypes } from '@/shared/interfaces/user';
import { shouldDisplayUserRoles } from '@/shared/components/auth/utils';
import { useCallback, useState } from 'react';
import { BlacklistByMissingUserRole } from '@/pages/data-pipeline/constants';
import { NavigationContextEnum } from '@/shared/interfaces/ui';

type GetRolesConfig = {
  credentials?: { token: string };
};

/**
 * @description Return and validateRolesAsync user-roles
 */
const useRoles = () => {
  const [isValidRoleList, setIsValidRoleList] = useState<any>(null);
  const appProperties = useAppProperties();

  const getRoles = async (config?: GetRolesConfig) => {
    const { credentials } = config || {};
    const onGetRolesSuccess = (data: any) => {
      if (data?.user?.auths) {
        appProperties.setProperty('user-roles', data?.user?.auths);
      }

      appProperties.setProperty(
        'applicationContext',
        NavigationContextEnum.dataPipeline
      );
    };

    let roles = await appProperties.getProperty('user-roles');

    if (!roles || credentials) {
      await appProperties.setProperty('user-roles', []);

      roles = await getManagerInfo(null, credentials).then(async (data) => {
        await appProperties.setProperty(
          'forwardTo',
          data.forwardTo || PreferredLoginEnum.ANALYTICS_PLATFORM
        );
        onGetRolesSuccess(data);

        return data?.user?.auths;
      });
    }

    return roles || [];
  };

  /**
   * @description validateRolesAsync if with given role list intersect with the user roles
   */
  const validateRolesAsync = async (rolesList: UserRolesTypes) => {
    const roles = await getRoles();
    return shouldDisplayUserRoles(rolesList, roles);
  };

  const validateRoles = (rolesList: UserRolesTypes) => {
    if (isValidRoleList === null) {
      (async () => {
        await getRoles().then((roles) =>
          setIsValidRoleList(shouldDisplayUserRoles(rolesList, roles))
        );
      })();
    }

    return isValidRoleList;
  };

  return {
    getRoles,
    validateRolesAsync,
    validateRoles,
  };
};

const useRolesBlackList = (list: BlacklistByMissingUserRole) => {
  const { validateRolesAsync } = useRoles();
  const getRolesValidationValues = useCallback(async () => {
    let results: any = {};
    await Promise.all(
      Object.keys(list).map((key: string) => validateRolesAsync(list[key]))
    ).then((values) => {
      Object.keys(list).forEach((key, i) => {
        results[key] = values[i];
      });

      return results;
    });

    return results;
  }, [validateRolesAsync, list]);

  return {
    getRolesValidationValues,
  };
};

const useProfileData = (queryConfig?: QueryConfig<any>, cacheID?: string) => {
  const dispatch = useDispatch();

  return useQuery(
    cacheID || QUERY_CACHE_MANAGER_INFO,
    () => getManagerInfo(dispatch),
    {
      staleTime: 1,
      ...queryConfig,
    }
  );
};

export { useProfileData, useRoles, useRolesBlackList };
