import {
  Connection as ConnectionIcon,
  Data as DataIcon,
  View as ViewIcon,
  Folder as FolderIcon,
  SendMessage as Send,
  AnalyticsIcon,
  VariablesIcon,
  SettingsIcon,
  DataQualityCheckIcon,
  Firewall,
  WorkflowIcon,
  VisualizationIcon,
  SQLEditorIcon,
} from '@/shared/assets/icons';
import i18next from 'i18next';
import { MenuItem } from '@/shared/interfaces/menu';
import { getVisualizationContext } from '../services/data-pipelines/visualization';
import { queryCache } from 'react-query';
import { QUERY_CACHE_VISUALIZATION_CONTEXT_QUERY_KEY } from './api';

const t = i18next.t.bind(i18next);

export const PAGE_SIZE = 16;

export const PAGE_SIZE_KEY = 'pageSize';

export const APPS_ANALYTICS_PLATFORM_PATH = 'analytics-platform';

// prettier-ignore
export const ROUTES = {

  BASE: {
    path: process.env.REACT_APP_ROOT_ROUTER || '#'
  },
  PUBLIC_URL: {
    path: process.env.PUBLIC_URL || '',
  },
  ROOT: {
    path: '/',
    component: () => import('@/pages/authentication/sign-in' /* webpackChunkName: "sign-in" */)
  },

  ROOT_LOGGED: {
    path: '/data-pipeline/catalog'
  },

  SIGN_IN: {
    path: '/sign-in',
    component: () => import('@/pages/authentication/sign-in' /* webpackChunkName: "sign-in" */)
  },

  SIGN_IN_COGNITO: {
    path: '/:domain/sign-in',
    component: () => import('@/pages/authentication/sign-in' /* webpackChunkName: "sign-in" */)
  },

  SIGN_UP_COGNITO: {
    path: '/:domain/sign-up',
    component: () => import('@/pages/authentication/sign-up' /* webpackChunkName: "sign-up" */)
  },

  ABOUT: {
    path: '/about',
  },

  ABOUT_ANALYTICS: {
    path: '/about/analytics-platform',
    component: () => import('@/pages/landing-pages/analytics-platform' /* webpackChunkName: "about-analytics-platform" */)
  },

  AUTHENTICATION_INVITATION: {
    path: '/authentication/invitation/:userId/:code',
    component: () => import('@/pages/authentication/new-password' /* webpackChunkName: "authentication-invitation" */)
  },

  AUTHENTICATION_MFA: {
    path: '/authentication/two-factor',
    component: () => import('@/pages/authentication/mfa' /* webpackChunkName: "authentication-mfa" */)
  },

  AUTHENTICATION_NEW_PASSWORD: {
    path: '/authentication/new-password/:username/:challenge?',
    component: () => import('@/pages/authentication/new-password' /* webpackChunkName: "new-password" */)
  },

  AUTHENTICATION_CONFIRM_SIGN_UP: {
    path: '/authentication/confirm/:username',
    component: () => import('@/pages/authentication/verification' /* webpackChunkName: "signup-confirmation" */)
  },

  USER_PROFILE: {
    path: '/app/manager/#/user'
  },

  MY_ACCOUNT: {
    path: '/my-account',
    component: () => import('@/pages/my-account' /* webpackChunkName: "my-account" */)
  },

  MY_ACCOUNT_PROFILE: {
    path: '/my-account/profile',
    component: () => import('@/pages/my-account' /* webpackChunkName: "my-account" */)
  },

  MY_ACCOUNT_PROFILE_SETUP: {
    path: '/my-account/profile/setup',
    component: () => import('@/pages/my-account/forms/complete-your-profile' /* webpackChunkName: "my-account-profile-setup" */)
  },

  MY_ACCOUNT_PROFILE_EDIT: {
    path: '/my-account/profile/edit',
    component: () => import('@/pages/my-account/forms' /* webpackChunkName: "my-account-profile-edit" */)
  },

  MY_ACCOUNT_MFA_EDIT: {
    path: '/my-account/mfa/edit',
    component: () => import('@/pages/my-account/forms/mfa' /* webpackChunkName: "my-account-mfa-edit" */)
  },

  DATA_PIPELINE: {
    path: '/data-pipeline/'
  },

  DATA_PIPELINE_DASHBOARD: {
    path: '/data-pipeline/dashboard'
  },

  DATA_PIPELINE_DATA_CATALOG: {
    path: '/data-pipeline/catalog',
    component: () => import( '@/pages/data-pipeline/catalog' /* webpackChunkName: "pipeline-catalog" */ )
  },

  DATA_PIPELINE_DATA_CATALOG_DETAILS: {
    path: '/data-pipeline/catalog/item/:source/:id/:tab',
    component: () => import('@/pages/data-pipeline/details' /* webpackChunkName: "pipeline-details" */)
  },

  DATA_PIPELINE_DETAILS_SHARE_DATA: {
    path: '/data-pipeline/catalog/item/:source/:id/share-access',
    component: () => import('@/pages/data-pipeline/share-access' /* webpackChunkName: "pipeline-share-access" */)
  },

  DATA_PIPELINE_DETAILS_DOWNLOAD_DATA: {
    path: '/data-pipeline/catalog/item/:source/:id/download',
    component: () => import('@/pages/data-pipeline/download-data' /* webpackChunkName: "pipeline-download-data" */)
  },

  DATA_PIPELINE_DETAILS_EDIT: {
    path: '/data-pipeline/catalog/item/:source/:id/edit',
    component: () => import('@/pages/data-pipeline/components/forms' /* webpackChunkName: "pipeline-details-edit" */)
  },

  DATA_PIPELINE_DETAILS_SQL_EDITOR: {
    path: '/data-pipeline/catalog/item/:source/:id/sql-editor',
    component: () => import('@/pages/data-pipeline/code-editor' /* webpackChunkName: "code-editor" */)
  },

  DATA_PIPELINE_DETAILS_EXTRA_CREATE: {
    path: '/data-pipeline/catalog/item/:source/:id/:tab/create',
    component: () => import('@/pages/data-pipeline/components/forms' /* webpackChunkName: "pipeline-edit" */)
  },

  DATA_PIPELINE_DETAILS_EXTRA_EDIT: {
    path: '/data-pipeline/catalog/item/:source/:id/:tab/edit',
    component: () => import('@/pages/data-pipeline/components/forms' /* webpackChunkName: "pipeline-edit" */)
  },

  DATA_PIPELINE_SCHEMAS: {
    path: '/data-pipeline/schemas',
    component: () => import( '@/pages/data-pipeline/schema' /* webpackChunkName: "pipeline-schema" */ )
  },

  DATA_PIPELINE_SCHEMAS_CREATE: {
    path: '/data-pipeline/schemas/create',
    component: () => import('@/pages/data-pipeline/components/forms' /* webpackChunkName: "pipeline-form" */)
  },

  DATA_PIPELINE_SCHEMAS_DETAILS: {
    path: '/data-pipeline/schemas/item/:id/details',
    component: () => import('@/pages/data-pipeline/details' /* webpackChunkName: "pipeline-details" */)
  },

  DATA_PIPELINE_SCHEMAS_DETAILS_EDIT: {
    path: '/data-pipeline/schemas/item/:id/details/edit',
    component: () => import('@/pages/data-pipeline/components/forms' /* webpackChunkName: "pipeline-details-edit" */)
  },

  DATA_PIPELINE_VIEWS: {
    path: '/data-pipeline/views',
    component: () => import( '@/pages/data-pipeline/views' /* webpackChunkName: "pipeline-views" */ )
  },

  DATA_PIPELINE_VIEWS_CREATE: {
    path: '/data-pipeline/views/create',
    component: () => import('@/pages/data-pipeline/components/forms' /* webpackChunkName: "pipeline-form" */)
  },

  DATA_PIPELINE_VIEWS_DETAILS: {
    path: '/data-pipeline/views/item/:id/:tab',
    component: () => import('@/pages/data-pipeline/details' /* webpackChunkName: "pipeline-details" */)
  },

  DATA_PIPELINE_VIEWS_DETAILS_SHARE_DATA: {
    path: '/data-pipeline/views/item/:id/share-access',
    component: () => import('@/pages/data-pipeline/share-access' /* webpackChunkName: "pipeline-share-access" */)
  },

  DATA_PIPELINE_VIEWS_DETAILS_EDIT: {
    path: '/data-pipeline/views/item/:id/details/edit',
    component: () => import('@/pages/data-pipeline/components/forms' /* webpackChunkName: "pipeline-details-edit" */)
  },

  DATA_PIPELINE_VIEWS_DETAILS_DUPLICATE: {
    path: '/data-pipeline/views/item/:id/duplicate',
    component: () => import('@/pages/data-pipeline/components/forms' /* webpackChunkName: "pipeline-details-edit" */)
  },

  DATA_PIPELINE_VIEWS_DETAILS_DOWNLOAD: {
    path: '/data-pipeline/views/item/:id/details/download/:source',
    component: () => import('@/pages/data-pipeline/download-data' /* webpackChunkName: "pipeline-details-edit" */)
  },

  DATA_PIPELINE_VIEWS_SQL_EDITOR: {
    path: '/data-pipeline/views/item/:id/sql-editor',
    component: () => import('@/pages/data-pipeline/code-editor' /* webpackChunkName: "code-editor" */)
  },

  DATA_PIPELINE_DATA_QUALITY_CHECKS: {
    path: '/data-pipeline/dataQualityChecks',
    component: () => import( '@/pages/data-pipeline/data-quality-checks' /* webpackChunkName: "pipeline-data-quality-checks" */ )
  },

  DATA_PIPELINE_DATA_QUALITY_CHECKS_CREATE: {
    path: '/data-pipeline/dataQualityChecks/create',
    component: () => import('@/pages/data-pipeline/components/forms' /* webpackChunkName: 'pipeline-form' */)
  },

  DATA_PIPELINE_DATA_QUALITY_CHECKS_DETAILS: {
    path: '/data-pipeline/dataQualityChecks/item/:id/:tab',
    component: () => import('@/pages/data-pipeline/details' /* webpackChunkName: "pipeline-details" */)
  },

  DATA_QUALITY_CHECKS_SHARE_CHECK: {
    path: '/data-pipeline/dataQualityChecks/item/:id/share-access',
    component: () => import('@/pages/data-pipeline/share-access' /* webpackChunkName: "pipeline-share-access" */)
  },

  DATA_PIPELINE_DATA_QUALITY_CHECKS_DETAILS_EDIT: {
    path: '/data-pipeline/dataQualityChecks/item/:id/details/edit',
    component: () => import('@/pages/data-pipeline/components/forms' /* webpackChunkName: "pipeline-details-edit" */)
  },

  DATA_QUALITY_CHECKS_SQL_EDITOR: {
    path: '/data-pipeline/dataQualityChecks/item/:id/sql-editor',
    component: () => import('@/pages/data-pipeline/code-editor' /* webpackChunkName: "code-editor" */)
  },

  DATA_PIPELINE_DATA_QUALITY_CHECK_GROUPS: {
    path: '/data-pipeline/dataQualityCheckGroups',
    component: () => import( '@/pages/data-pipeline/data-quality-checks/groups' /* webpackChunkName: "pipeline-data-quality-check-groups" */ )
  },

  DATA_PIPELINE_DATA_QUALITY_CHECK_GROUPS_CREATE: {
    path: '/data-pipeline/dataQualityCheckGroups/create',
    component: () => import('@/pages/data-pipeline/components/forms' /* webpackChunkName: 'pipeline-form' */)
  },

  DATA_PIPELINE_DATA_QUALITY_CHECK_GROUPS_DETAILS: {
    path: '/data-pipeline/dataQualityCheckGroups/item/:id/details',
    component: () => import('@/pages/data-pipeline/details' /* webpackChunkName: "pipeline-details" */)
  },

  DATA_PIPELINE_DATA_QUALITY_CHECK_GROUPS_DETAILS_EDIT: {
    path: '/data-pipeline/dataQualityCheckGroups/item/:id/details/edit',
    component: () => import('@/pages/data-pipeline/components/forms' /* webpackChunkName: "pipeline-details-edit" */)
  },

  DATA_PIPELINE_WORKFLOWS: {
    path: '/data-pipeline/workflows',
    component: () => import( '@/pages/data-pipeline/workflows' /* webpackChunkName: "pipeline-workflows" */ )
  },

  DATA_PIPELINE_WORKFLOWS_CREATE: {
    path: '/data-pipeline/workflows/create',
    component: () => import('@/pages/data-pipeline/components/forms' /* webpackChunkName: "pipeline-form" */)
  },

  DATA_PIPELINE_WORKFLOWS_DETAILS: {
    path: '/data-pipeline/workflows/item/:id/:tab',
    component: () => import('@/pages/data-pipeline/details' /* webpackChunkName: "pipeline-details" */)
  },

  DATA_PIPELINE_WORKFLOWS_DETAILS_SHARE: {
    path: '/data-pipeline/workflows/item/:id/share-access',
    component: () => import('@/pages/data-pipeline/share-access' /* webpackChunkName: "pipeline-share-access" */)
  },

  DATA_PIPELINE_WORKFLOWS_DETAILS_EDIT: {
    path: '/data-pipeline/workflows/item/:id/details/edit',
    component: () => import('@/pages/data-pipeline/components/forms' /* webpackChunkName: "pipeline-details-edit" */)
  },

  DATA_PIPELINE_DATA_CONNECT: {
    path: '/data-pipeline/catalog/connect',
    component: () => import('@/pages/data-pipeline/connect' /* webpackChunkName: "pipeline-connect" */)
  },

  DATA_PIPELINE_CONNECTION_INFO: {
    path: '/data-pipeline/connection-info',
    component: () => import('@/pages/data-pipeline/connection-info' /* webpackChunkName: "pipeline-connection-info" */)
  },
  DATA_PIPELINE_VISUALIZATION: {
    path: '/visualization',
    component: () => import('@/pages/data-pipeline/visualization' /* webpackChunkName: "visualization" */)
  },

  DATA_PIPELINE_DATA_FORM: {
    path: '/data-pipeline/catalog/connect/:source',
    component: () => import('@/pages/data-pipeline/components/forms' /* webpackChunkName: "pipeline-form" */)
  },

  DATA_PIPELINE_PUBLIC_DATASETS: {
    path: `/data-pipeline/catalog/connect/publicDataset`,
    component: () => import('@/pages/data-pipeline/public-datasets' /* webpackChunkName: "pipeline-public-datasets" */)
  },

  DATA_PIPELINE_PUBLIC_DATASET_DETAILS: {
    path: `/data-pipeline/catalog/connect/:source/:publicDataSetKey`,
    component: () => import('@/pages/data-pipeline/components/forms' /* webpackChunkName: "pipeline-details" */)
  },

  DATA_PIPELINE_DOWNLOAD_PROXY: {
    path: '/download-proxy',
    component: () => import('@/pages/data-pipeline/download-proxy' /*webpackChunkName: "pipeline-download-proxy" */)
  },

  DESTINATIONS_CATALOG: {
    path: '/data-pipeline/destinations',
    component: () => import('@/pages/data-pipeline/destination') /* webpackChunkName": "pipeline-category */
  },

  DESTINATIONS_DATA_CONNECT: {
    path: '/data-pipeline/destinations/connect',
    component: () => import('@/pages/destination/connect')
  },

  DESTINATIONS_DATA_FORM: {
    path: '/data-pipeline/destinations/connect/:source',
    component: () => import('@/pages/data-pipeline/components/forms/destination' /* webpackChunkName: "pipeline-form" */)
  },

  DESTINATIONS_DETAILS: {
    path: '/data-pipeline/destinations/item/:source/:id/:tab',
    component: () => import('@/pages/data-pipeline/details' /* webpackChunkName: "pipeline-details" */)
  },

  DESTINATIONS_DETAILS_EDIT: {
    path: '/data-pipeline/destinations/item/:source/:id/edit',
    component: () => import('@/pages/data-pipeline/components/forms/destination' /* webpackChunkName: "pipeline-details-edit" */)
  },
  DESTINATIONS_SHARE_DATA: {
    path: '/data-pipeline/destinations/item/:source/:id/share-access',
    component: () => import('@/pages/data-pipeline/share-access' /* webpackChunkName: "pipeline-share-access" */)
  },
  SQL_EDITOR: {
    path: '/sql-editor',
    component: () => import('@/shared/components/CodeEditor/SqlEditor/SqlEditor' /* webpackChunkName: "sql-editor" */)
  },
  VARIABLES: {
    path: '/variables',
    component: () => import('@/pages/variables' /* webpackChunkName: "variables" */)
  },
  FIREWALL_RULES: {
    path: '/firewall-rules',
    component: () => import('@/pages/firewall' /* webpackChunkName: "variables" */)
  },
  SETTINGS: {
    path: '/settings',
    component: () => import('@/pages/settings' /* webpackChunkName: "settings" */)
  },
};

export const MENU_DEFAULT_ITEMS: MenuItem[] = [
  {
    key: 'dataCatalog',
    label: () => t('common:components.menu.dataCatalog'),
    to: ROUTES.DATA_PIPELINE_DATA_CATALOG.path,
    icon: DataIcon,
    query: {
      [PAGE_SIZE_KEY]: PAGE_SIZE,
    },
    roles: ['ROLE_SEE_DATA_PIPELINE', 'ROLE_MANAGE_DATA_PIPELINE'],
  },
  {
    key: 'dataPipelineViews',
    label: () => t('common:components.menu.views'),
    to: ROUTES.DATA_PIPELINE_VIEWS.path,
    icon: ViewIcon,
    roles: ['ROLE_SEE_VIEW', 'ROLE_MANAGE_VIEW'],
  },
  {
    key: 'schemas',
    label: () => t('common:components.menu.schemas'),
    to: ROUTES.DATA_PIPELINE_SCHEMAS.path,
    icon: FolderIcon,
    roles: ['ROLE_SEE_SCHEMA', 'ROLE_MANAGE_SCHEMA'],
  },
  {
    key: 'dataQualityChecks',
    label: () => t('common:components.menu.dataQualityChecks'),
    to: ROUTES.DATA_PIPELINE_DATA_QUALITY_CHECKS.path,
    icon: DataQualityCheckIcon,
    roles: ['ROLE_SEE_DATA_QUALITY_CHECK', 'ROLE_MANAGE_DATA_QUALITY_CHECK'],
  },
  {
    key: 'destinations',
    label: () => t('common:components.menu.destinations'),
    to: ROUTES.DESTINATIONS_CATALOG.path,
    icon: Send,
    roles: ['ROLE_SEE_DESTINATION', 'ROLE_MANAGE_DESTINATION'],
  },
  {
    key: 'workflows',
    label: () => t('common:components.menu.workflows'),
    to: ROUTES.DATA_PIPELINE_WORKFLOWS.path,
    icon: WorkflowIcon,
    roles: ['ROLE_SEE_WORKFLOW', 'ROLE_MANAGE_WORKFLOW'],
  },
  {
    key: 'dataBrowser',
    label: () => t('common:components.menu.dataBrowser'),
    to: ROUTES.SQL_EDITOR.path,
    icon: SQLEditorIcon,
    roles: ['ROLE_SEE_VIEW', 'ROLE_MANAGE_VIEW'],
  },
  {
    key: 'variables',
    label: () => t('common:components.menu.variables'),
    to: ROUTES.VARIABLES.path,
    icon: VariablesIcon,
    roles: ['ROLE_SEE_VARIABLE', 'ROLE_MANAGE_VARIABLE'],
  },
  {
    key: 'firewall-rules',
    label: () => t('common:components.menu.firewallRules'),
    to: ROUTES.FIREWALL_RULES.path,
    icon: Firewall,
    roles: ['ROLE_MANAGE_FIREWALL_RULE'],
  },
  {
    key: 'settings',
    label: () => t('common:components.menu.settings'),
    to: ROUTES.SETTINGS.path,
    icon: SettingsIcon,
    roles: ['ROLE_MANAGE_SETTING'],
  },
  {
    key: 'visualizationUrl',
    label: () => t('common:components.menu.dataVisualization'),
    to: ROUTES.DATA_PIPELINE_VISUALIZATION.path,
    icon: VisualizationIcon,
    validate: async () => {
      return queryCache
        .fetchQuery(QUERY_CACHE_VISUALIZATION_CONTEXT_QUERY_KEY, {
          queryFn: getVisualizationContext,
        })
        .then(({ visualizationEnabled }) => !visualizationEnabled)
        .catch((_) => true);
    },
  },
  {
    key: 'dataPipelineConnectionInfo',
    label: () => t('common:components.menu.connectionInformation'),
    to: ROUTES.DATA_PIPELINE_CONNECTION_INFO.path,
    icon: ConnectionIcon,
    roles: [
      'ROLE_VIEW_DATA_ANALYTICS_PLATFORM',
      'ROLE_VIEW_DATA_ALL_DATA_PIPELINE',
    ],
  },

  {
    key: 'analyticsPlatform',
    label: () => t('common:components.menu.analyticsPlatform'),
    to: ROUTES.ABOUT_ANALYTICS.path,
    icon: AnalyticsIcon,
    roles: ['ROLE_SEE_DATA_PIPELINE', 'ROLE_MANAGE_DATA_PIPELINE'],
    hide: () => true,
  },
];
