import { ROUTES } from '@/shared/constants/navigation';
import loadable from '@loadable/component';
import { fallback } from '@/routes/index';
import { Route } from '@/shared/interfaces/navigation';

// prettier-ignore
export default [
  {
    path: ROUTES.MY_ACCOUNT_MFA_EDIT.path,
    component: loadable(ROUTES.MY_ACCOUNT_MFA_EDIT.component, { fallback }),
    modal: {
      enabled: true,
      width: '70.5%',
    },
  },
  {
    path: [ROUTES.MY_ACCOUNT_PROFILE.path, '*'],
    exact: true,
    component: loadable(ROUTES.MY_ACCOUNT_PROFILE.component, { fallback }),
    routes: [
      {
        path: ROUTES.MY_ACCOUNT_PROFILE_EDIT.path,
        component: loadable(ROUTES.MY_ACCOUNT_PROFILE_EDIT.component, { fallback }),
        modal: {
          enabled: true,
          width: '81.5%',
        },
      },
    ]
  },
] as Array<Route>;
