import {
  InfoIcon,
  SuccessIcon,
  WarningIcon,
  ErrorIcon,
} from '@/shared/assets/icons';

type NotificationType = 'info' | 'success' | 'warning' | 'error';

export const toastTypes: { [s in NotificationType]: NotificationType } = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'error',
};

const iconMap: { [s in NotificationType]: JSX.Element } = {
  info: <InfoIcon />,
  success: <SuccessIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
};

const ToastIcon = ({ type }: { type: NotificationType }) => {
  return iconMap?.[type];
};

export default ToastIcon;
