import * as React from 'react';
import { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Col,
  ICON_TYPES,
  Menu,
  Popover,
  Row,
  Spinner,
  Text,
  View,
} from '@baosystems/nebula';
import { ProfileMenuProps } from './interfaces';
import styled, { useTheme } from 'styled-components';
import styles from './styles';
import { USER_ROLES_LIST } from '@/shared/constants/user';
import { Client, User } from '@/shared/interfaces/user';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@/shared/constants/navigation';
import { useProfileData } from '@/shared/hooks/profile';
// TODO: Move to ICON_TYPES to icons component

const ProfileContainer = styled(Row)(styles.profileContainer);
const MenuContainer = styled(View)(styles.menuContainer);
const MenuDescription = styled(Text)(styles.menuDescription);
const ClientName = styled(Text)(styles.clientName);
const ProfileEmail = styled(Text)(styles.email);
const Company = styled(View)(styles.company);
const Chevron = styled('span')(styles.chevron);
const PopoverHeader = styled(Col)(styles.popoverHeader);

const ProfileMenu: React.FunctionComponent<any> = ({
  methods,
}: ProfileMenuProps) => {
  const menuContainer = useRef(null);
  const { t } = useTranslation();
  const { tokens }: any = useTheme();
  const { data = [], isFetching } = useProfileData();

  if (isFetching || !data) {
    return (
      <MenuContainer>
        <Spinner size="small" light />
      </MenuContainer>
    );
  }

  const { user, client }: { user: User; client: Client } = data;
  const menu: any = [
    {
      label: t('common:components.menu.profile'),
      to: `${ROUTES.USER_PROFILE.path}/${user?.id}`,
    },
    {
      label: t('common:components.menu.help'),
      icon: null,
      to: 'https://docs.ap.baosystems.com/',
      target: '_blank',
    },
    {
      label: t('common:components.menu.logOut'),
      to: null,
      onClick: methods.signOut,
      icon: ICON_TYPES.LEAVE,
    },
  ].filter(Boolean);

  const menuProfile = (
    <MenuContainer>
      <PopoverHeader>
        <ClientName>{user?.name ? user?.name : user?.email}</ClientName>
        <ProfileEmail>{user?.email}</ProfileEmail>
        <Company>{client?.name}</Company>
      </PopoverHeader>
      <Menu mode="inline">
        {menu.map((item: any) => (
          <Menu.Item
            key={item.label}
            onClick={item.onClick}
            disabled={item.to?.endsWith('null')}
          >
            <View
              as={item.to ? 'a' : 'div'}
              href={item.to || null}
              target={item.target || null}
              className="menu-item"
            >
              <Text>
                {item.label}
                {item.description && (
                  <MenuDescription>{item.description}</MenuDescription>
                )}
              </Text>
            </View>
          </Menu.Item>
        ))}
      </Menu>
    </MenuContainer>
  );

  return (
    <ProfileContainer>
      <div ref={menuContainer}>
        <Popover
          trigger="click"
          content={menuProfile}
          arrowPointAtCenter
          placement="bottom"
          getPopupContainer={() => menuContainer.current}
        >
          <Row
            type="flex"
            justify="space-around"
            align="middle"
            gutter={[10, 0]}
            style={{ flexWrap: 'nowrap' }}
          >
            <Col>
              <Avatar
                size={40}
                style={{
                  color: tokens.COLOR_BRAND_500,
                  backgroundColor: tokens.COLOR_NEUTRAL_WHITE,
                }}
              >
                {user?.username?.trim().charAt(0).toUpperCase()}
              </Avatar>
            </Col>
            <Col>
              <Chevron />
            </Col>
          </Row>
        </Popover>
      </div>
    </ProfileContainer>
  );
};

ProfileMenu.propTypes = {
  data: PropTypes.shape({
    user: PropTypes.shape({
      username: PropTypes.string,
      email: PropTypes.string,
      auths: PropTypes.arrayOf(PropTypes.oneOf(USER_ROLES_LIST)),
    }),
  }),
  menu: PropTypes.arrayOf(PropTypes.any),
};

ProfileMenu.defaultProps = {
  data: {
    user: {},
    client: {},
  },
};

export default ProfileMenu;
