import { Route, Switch } from 'react-router-dom';
import LayoutDrawer from './LayoutDrawer';
import { DrawerProvider } from './LayoutDrawerContext';
import { useResponsive } from 'react-hooks-responsive';
import { BREAKPOINTS, DEVICE_TYPE } from '@/shared/constants';
import { useParams } from 'react-router';

const LayoutRoutes = ({ routes, getContainer, children }: any): any => {
  const { screenIsAtMost } = useResponsive(BREAKPOINTS);
  const isMobile = screenIsAtMost(DEVICE_TYPE.MOBILE);
  const routeParams: any = useParams();

  const replaceRoutePathParams = (callbackURL: any) => {
    let path = callbackURL;
    Object.keys(routeParams).forEach(
      (param: any) => (path = path?.replace(`:${param}`, routeParams[param]))
    );
    return path;
  };

  if (!Array.isArray(routes)) {
    return null;
  }

  return (
    <>
      <Switch>
        {routes.map(
          ({ path, routes: nestedRoutes = [], modal = {}, ...route }) => (
            <Route
              key={path}
              path={path}
              render={(props) =>
                modal?.enabled ? (
                  <DrawerProvider>
                    <LayoutDrawer
                      width={
                        props.match.isExact && !isMobile ? modal.width : '100%'
                      }
                      callbackURL={replaceRoutePathParams(modal.callbackURL)}
                      getContainer={getContainer}
                      isMobile={isMobile}
                    >
                      <route.component
                        {...props}
                        key={path}
                        routes={routes}
                        route={route}
                      />
                    </LayoutDrawer>
                  </DrawerProvider>
                ) : (
                  <LayoutRoutes routes={nestedRoutes}>
                    <route.component
                      {...props}
                      key={path}
                      routes={nestedRoutes}
                      route={route}
                    />
                  </LayoutRoutes>
                )
              }
            />
          )
        )}
      </Switch>
      {children}
    </>
  );
};

export default LayoutRoutes;
