import { Auth } from 'aws-amplify';
import {
  AuthenticationChallengesEnum,
  ClientDomainEnum,
  MFATypeEnum,
} from '@/shared/interfaces/user';
import { CognitoUser } from 'amazon-cognito-identity-js';

const cognitoConfig = {
  poolDomain: `https://${process.env.REACT_APP_COGNITO_DOMAIN}`,
};

const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_REGION,
    identityPoolRegion: process.env.REACT_APP_COGNITO_IDENTITY_POOL_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType:
      process.env.REACT_APP_COGNITO_AUTHENTICATION_FLOW_TYPE,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin,
      responseType: 'code',
    },
  },
};

interface UserAuth {
  username: string;
  password: string;
  email?: string;
  name?: string;
}

const signUp = async ({ name, username, password, email }: UserAuth) =>
  Auth.signUp({
    username,
    password,
    attributes: {
      email,
      name: name || '',
      'custom:domain': ClientDomainEnum.REGULAR,
    },
  });

async function signIn({ username, password }: UserAuth) {
  try {
    await Auth.currentSession().then(() => {
      return signOut();
    });
  } catch (e) {
    console.error(e);
  }

  return await Auth.signIn(username, password);
}

async function signOut() {
  return await Auth.signOut({ global: true });
}

async function forgotPassword({ username }: Omit<UserAuth, 'password'>) {
  return await Auth.forgotPassword(username);
}

async function getAuthenticatedUser() {
  return await Auth.currentAuthenticatedUser({ bypassCache: true });
}

async function updateUserAttributes(data: any) {
  const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
  return await Auth.updateUserAttributes(user, data);
}

async function changePassword({ oldPassword, newPassword }: any) {
  return Auth.currentAuthenticatedUser({ bypassCache: true }).then((user) => {
    return Auth.changePassword(user, oldPassword, newPassword);
  });
}

async function completeNewPassword({
  user,
  newPassword,
}: {
  user: {
    username: string;
    password: string;
  };
  newPassword: string;
}) {
  return await Auth.signIn(user.username, user.password).then((userSession) => {
    if (userSession.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return Auth.completeNewPassword(userSession, newPassword, {
        name: user.username,
      });
    }
    return userSession;
  });
}

async function confirmSignUp(username: string, code: string) {
  return await Auth.confirmSignUp(username, code);
}

async function confirmSignIn(
  user: CognitoUser,
  code: string,
  mfaType: keyof typeof MFATypeEnum = 'SOFTWARE_TOKEN_MFA'
) {
  return await Auth.confirmSignIn(user, code, mfaType);
}

async function resendConfirmationCode(username: string) {
  return await Auth.resendSignUp(username);
}

async function setupMFA() {
  const user = await Auth.currentAuthenticatedUser();

  return {
    code: await Auth.setupTOTP(user),
    user,
  } as {
    code: string;
    user: any;
  };
}

async function getPreferredMFA(): Promise<AuthenticationChallengesEnum> {
  const user = await Auth.currentAuthenticatedUser();
  return (await Auth.getPreferredMFA(user, {
    bypassCache: false,
  })) as AuthenticationChallengesEnum;
}

async function enableMFA({ code }: { code: string }) {
  const user = await Auth.currentAuthenticatedUser();

  return await Auth.verifyTotpToken(user, code).then(() => {
    Auth.setPreferredMFA(user, 'TOTP');
  });
}

async function disableMFA() {
  const user = await Auth.currentAuthenticatedUser();

  return await Auth.setPreferredMFA(user, 'NOMFA');
}

async function verifyPhoneNumber({
  phone_number,
  code,
}: {
  phone_number?: string;
  code?: string;
}) {
  if (phone_number) {
    await updateUserAttributes({ phone_number });
    return await Auth.verifyCurrentUserAttribute('phone_number');
  } else if (code) {
    return await Auth.verifyCurrentUserAttributeSubmit('phone_number', code);
  }
}

export {
  cognitoConfig,
  awsConfig,
  getAuthenticatedUser,
  setupMFA,
  getPreferredMFA,
  enableMFA,
  disableMFA,
  signUp,
  signIn,
  signOut,
  forgotPassword,
  changePassword,
  completeNewPassword,
  confirmSignUp,
  confirmSignIn,
  resendConfirmationCode,
  updateUserAttributes,
  verifyPhoneNumber,
};
