export const payloadTransformConnectionTestAPI = (values: any) => ({
  url: values.webApi?.baseURL,
  username: values.webApi?.username,
  password: values.webApi?.password,
});

export const payloadTransformConnectionTestJDBC = (values: any) => ({
  jdbcSource: {
    database: values.database?.databaseName,
    hostname: values.database?.hostname,
    port: values.database?.port,
    username: values.database?.databaseUsername,
    password: values.database?.databasePassword,
    ssl: values.database?.ssl?.enabled,
    trustServerCertificate: values.database?.trustServerCertificate,
  },
});

export const payloadTransformConnectionTestAPISource = (values: any) => ({
  url: values.apiSource?.url,
  authToken: values.apiSource?.authToken,
  username: values.apiSource?.username,
  password: values.apiSource?.password,
  project: values.apiSource?.project,
});
