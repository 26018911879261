import { css } from 'styled-components';

const styles = {
  container: () => css`
    cursor: pointer;
    flex-wrap: nowrap;
    display: inline-block;

    .apps-icon {
      opacity: 1;
      transition: opacity 200ms ease-in-out;

      &.active {
        opacity: 0.8;
      }
    }
    .ant-popover-inner {
      padding-top: 18px;
    }
    .selector-container {
      padding: 0 !important;
      margin: 0 !important;
      line-height: 0;
    }

    && {
      .ant-popover-inner {
        border-radius: 4px;
        overflow: hidden;
        border: 1px solid ${(props) => props.theme.tokens.COLOR_NEUTRAL_GREY_4};
      }
      .ant-popover-content {
        position: relative;
        left: 20px;
        top: 10px;
      }
      .ant-popover-inner-content {
        padding: 0;
      }
    }
  `,
  menuContainer: () => css`
    width: 304px;
    && {
      .ant-menu-inline .ant-menu-item {
        color: ${(props) => props.theme.tokens.COLOR_NEUTRAL_BLACK};
        height: auto;
        line-height: 21px;
        padding-top: ${(props) => props.theme.tokens.SPACING_NORMAL_MIN}px;
        padding-bottom: ${(props) => props.theme.tokens.SPACING_NORMAL_MIN}px;
        margin: 0;

        &:last-child {
          margin-bottom: 19px;
        }

        &:hover {
          color: ${(props) => props.theme.tokens.COLOR_BASE_PRIMARY};
        }

        &:after {
          background-color: ${(props) => props.theme.tokens.COLOR_BASE_LIGHT};
          border-right: none;
          right: initial;
          left: 0;
          border-left: 2px solid
            ${(props) => props.theme.tokens.COLOR_BASE_PRIMARY};
        }

        &.ant-menu-item-selected {
          color: ${(props) => props.theme.tokens.COLOR_NEUTRAL_BLACK};

          &.ant-menu-item-disabled {
            span {
              color: ${(props) => props.theme.tokens.COLOR_NEUTRAL_BLACK};
            }
          }

          &:after {
            background-color: ${(props) => props.theme.tokens.COLOR_BASE_LIGHT};
            border-right: none;
            right: initial;
            left: 0;
            border-left: 2px solid
              ${(props) => props.theme.tokens.COLOR_BASE_PRIMARY};
          }
        }
      }
    }
  `,
  title: () => css`
    && {
      padding: ${(props) => props.theme.tokens.SPACING_NORMAL_MIN}px
        ${(props) => props.theme.tokens.SPACING_NORMAL_MAX}px;
      display: block;
      color: ${(props) => props.theme.tokens.COLOR_NEUTRAL_BLACK};
      font-size: 1.6rem;
      font-weight: bold;
    }
  `,
};

export default styles;
