import {
  API_BASE_URL,
  API_PATH_DATA_PIPELINE_CONFIG,
  API_PATH_DATA_PIPELINE_CONNECTION_STATUS,
  API_PATH_DATA_PIPELINE_SYSTEM_INFO,
  API_PATH_DATA_PIPELINE_TAGS,
  API_PATH_DATA_PIPELINE_USER_LIST,
  API_PATH_DATA_SHARING,
} from '@/shared/constants/api';
import { handleAccess, showIfError } from '@/shared/utils/api-utils';
import { GetTags } from '@/shared/interfaces/services';
import { generatePath } from 'react-router';
import { ConnectionInfoPayload } from '@/shared/interfaces/api';

/**
 * @method getConnectionInfo
 * @description Fetch data pipeline connection information
 * @returns {Promise<any>}
 */
export const getConnectionInfo = (): Promise<ConnectionInfoPayload> =>
  fetch(API_BASE_URL + API_PATH_DATA_PIPELINE_CONFIG, {
    credentials: 'include',
  })
    .then(handleAccess)
    .then(showIfError)
    .then((res) => res.json());

/**
 * @method getDataPipelineConnectionInfo
 * @description Get connection status for specific data pipeline
 * @returns {Promise<any>}
 */
export const getDataPipelineConnectionInfo = ({
  source,
  id,
}: {
  source?: any;
  id?: any;
}) =>
  fetch(
    API_BASE_URL +
      generatePath(API_PATH_DATA_PIPELINE_CONNECTION_STATUS, { source, id }),
    {
      credentials: 'include',
    }
  )
    .then(handleAccess)
    .then(showIfError)
    .then((res) => res.json());

/**
 * @method getDataPipelineSystemInfo
 * @description Fetch data pipeline system information
 * @returns {Promise<any>}
 */
export const getDataPipelineSystemInfo = () =>
  fetch(API_BASE_URL + API_PATH_DATA_PIPELINE_SYSTEM_INFO, {
    credentials: 'include',
  })
    .then(handleAccess)
    .then(showIfError)
    .then((res) => res.json());

/**
 * @method getUserList
 * @description Used to get a user list with access to data pipelines
 * @return {Promise<any>}
 */
export const getUserList = () =>
  fetch(API_BASE_URL + API_PATH_DATA_PIPELINE_USER_LIST, {
    credentials: 'include',
  })
    .then(handleAccess)
    .then(showIfError)
    .then((res) => res.json());

/**
 * @method searchSharing
 * @description Fetch available users and groups to share with.
 * @param {string} terms
 * @returns {Promise<any>}
 */
export const searchSharing = (terms: string) =>
  fetch(API_BASE_URL + API_PATH_DATA_SHARING.replace(':searchQuery', terms), {
    credentials: 'include',
  })
    .then(handleAccess)
    .then(showIfError)
    .then((res) => res.json());

/**
 * @method searchTags
 * @description Fetch data pipeline available tags.
 * @param {string} tags
 * @returns {Promise<any>}
 */
export const searchTags = ({ tagName }: GetTags) =>
  fetch(
    API_BASE_URL + API_PATH_DATA_PIPELINE_TAGS.replace(':tagName', tagName),
    {
      credentials: 'include',
    }
  )
    .then(handleAccess)
    .then(showIfError)
    .then((res) => res.json());
