import { css } from 'styled-components';
import { BREAKPOINTS } from '@/shared/constants';

const styles = {
  profileContainer: () => css`
    cursor: pointer;
    flex-wrap: nowrap;
    @media (max-width: ${BREAKPOINTS.md}px) {
      .desktop {
        display: none;
      }
    }

    && {
      .ant-popover-inner {
        border-radius: 4px;
        overflow: hidden;
        border: 1px solid ${(props) => props.theme.tokens.COLOR_NEUTRAL_GREY_4};
      }
      .ant-popover-content {
        position: relative;
        left: -30px;
      }
      .ant-popover-inner-content {
        padding: 0;
      }
    }
  `,
  menuContainer: () => css`
    width: 315px;
    && {
      .ant-menu-inline .ant-menu-item {
        color: ${(props) => props.theme.tokens.COLOR_NEUTRAL_BLACK};
        height: auto;
        line-height: 21px;
        padding-top: ${(props) => props.theme.tokens.SPACING_NORMAL_MIN}px;
        padding-bottom: ${(props) => props.theme.tokens.SPACING_NORMAL_MIN}px;
        margin: 0;

        &:after {
          background-color: #e3f7fb;
          border-right: none;
          right: initial;
          left: 0;
          border-left: 2px solid
            ${(props) => props.theme.tokens.COLOR_BASE_PRIMARY};
        }

        .ant-menu-item-selected {
          background-color: #e3f7fb;
          color: ${(props) => props.theme.tokens.COLOR_NEUTRAL_BLACK};

          &:after {
            background-color: #e3f7fb;
            border-right: none;
            right: initial;
            left: 0;
            border-left: 2px solid
              ${(props) => props.theme.tokens.COLOR_BASE_PRIMARY};
          }
        }
      }
    }
    .menu-item:hover {
      color: ${(props) => props.theme.tokens.COLOR_BRAND_500} !important;
    }
  `,
  clientName: () => css`
    && {
      display: block;
      color: ${(props) => props.theme.tokens.COLOR_NEUTRAL_GREY_7};
      font-size: 1.6rem;
    }
  `,
  menuDescription: () => css`
    && {
      display: block;
      color: ${(props) => props.theme.tokens.COLOR_NEUTRAL_GRAY_700};
      font-size: 1.4rem;
    }
  `,
  chevron: () => css`
    display: block;
    width: 8px;
    height: 8px;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    transform: rotate(45deg);
    @media (max-width: ${BREAKPOINTS.md}px) {
      margin: 0 8px 0 6px;
    }
  `,
  name: () => css`
    display: block;
    font-size: 1.6rem;
    line-height: 2.6rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${(props) => props.theme.tokens.COLOR_NEUTRAL_WHITE};
  `,
  email: () => css`
    && {
      font-size: 1.6rem;
      line-height: 1.68rem;
      min-width: 130px;
    }
    display: block;
    white-space: nowrap;
    opacity: 0.9;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #7a7f99;
  `,
  company: () => css`
    margin-top: 1.0625rem;
    font-size: 1.6rem;
    color: ${(props) => props.theme.tokens.COLOR_NEUTRAL_GREY_6};
  `,
  popoverHeader: () => css`
    padding: ${(props) => props.theme.tokens.SPACING_NORMAL_MIN}px
      ${(props) => props.theme.tokens.SPACING_NORMAL_MAX}px;
    border-bottom: 1px solid #d2d4d9;
  `,
};

export default styles;
