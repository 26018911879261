import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setProperty } from '@/shared/state/properties/actions';

import App from './App';

const mapStateToProps = (state) => ({
  theme: state.properties.theme,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setProperty,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
