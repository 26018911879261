import { css, keyframes } from 'styled-components';
const ANIMATION_DURATION = '1.5s';

const indeterminate = keyframes`
  from {
    background-position-x: 400%;
  }
  to {
    background-position-x: 200%;
  }
`;

const getInderteminateAnimation = (isIndeterminate) => {
  if (isIndeterminate) {
    return css`
      background-size: 210% 100%;
      animation: ${indeterminate} ${ANIMATION_DURATION} linear forwards infinite;
    `;
  }
};

const getAlignment = (align) => {
  switch (align) {
    case 'left':
      return { justifyContent: 'flex-start', textAlign: 'left' };
    case 'right':
      return { justifyContent: 'flex-end', textAlign: 'right' };
    case 'center':
      return { justifyContent: 'center', textAlign: 'center' };
    default:
      return { justifyContent: 'flex-start', textAlign: 'left' };
  }
};

const getProgress = (isIndeterminate, progress) =>
  isIndeterminate ? 50 : progress;

export default {
  loaderContainer: ({ theme: { tokens } }) => css`
    padding-top: ${tokens.SPACING_BIG_MIN}px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${tokens.COLOR_NEUTRAL_GRAY_100};
    z-index: 100;
  `,
  title: ({ theme: { tokens } }) => css`
    font-size: 1.4rem;
    text-transform: uppercase;
    margin-bottom: ${tokens.SPACING_BIG_MIN}px;
  `,
  description: ({ theme: { tokens }, align }) => css`
    white-space: nowrap;
    font-size: 1.4rem;
    color: ${tokens.COLOR_NEUTRAL_GRAY_700};
    margin-top: ${tokens.SPACING_SMALL_MAX}px;
    ${getAlignment(align)}
  `,
  component: ({ theme: { tokens }, isIndeterminate, progress, align }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    padding: ${tokens.SPACING_NORMAL_BASE}px;
    border-radius: 4px;
    font-size: 1.6rem;
    color: ${tokens.COLOR_BASE_SECONDARY};
    line-height: 2.6rem;
    background: linear-gradient(
      90deg,
      ${isIndeterminate ? `#e3f7fb 0%,` : ``} #62c2d1
        ${getProgress(isIndeterminate, progress) * 0.7}%,
      #c6ecf3 ${getProgress(isIndeterminate, progress) * 1.4}%,
      #e3f7fb 100%
    );
    ${getInderteminateAnimation(isIndeterminate)}
    ${getAlignment(align)}
  `,
};
