import { Suspense } from 'react';
import { HashRouter } from 'react-router-dom';
import configureStore from 'redux-mock-store';
import properties from '../state/properties/initial-state';
import '../../i18n';
import { ReactQueryCacheProvider, QueryCache } from 'react-query';
import { renderHook } from '@testing-library/react-hooks/dom';

const mockStore = configureStore();
const store = mockStore({ properties });

const withTranslation = (Component: any, props: any) => (
  <Suspense fallback={null}>
    <Component {...props} />
  </Suspense>
);

const withRouter = (component: any) => (
  <HashRouter basename={'/'}>{component}</HashRouter>
);

const reactQueryWrapper = ({ children }: any) => {
  const queryCache = new QueryCache();
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      {children}
    </ReactQueryCacheProvider>
  );
};

const renderReactQueryHook = (
  callback: (props: any) => unknown,
  initialProps?: any
) =>
  renderHook(callback, {
    wrapper: reactQueryWrapper,
    initialProps,
  });

export {
  store,
  withTranslation,
  withRouter,
  reactQueryWrapper,
  renderReactQueryHook,
};
