import { ROUTES } from '@/shared/constants/navigation';
import loadable from '@loadable/component';
import { fallback } from '@/routes/index';
import { DataPipelinePageVariation } from '@/shared/constants/dataPipelines';

// prettier-ignore

export default [
  {
    path: ROUTES.DESTINATIONS_DETAILS.path,
    component: loadable(ROUTES.DESTINATIONS_DETAILS.component, { fallback }),
    group: DataPipelinePageVariation.DESTINATION,
    routes: [
      {
        path: ROUTES.DESTINATIONS_DETAILS_EDIT.path,
        component: loadable(ROUTES.DESTINATIONS_DETAILS_EDIT.component, { fallback }),
        group: DataPipelinePageVariation.DESTINATION,
        modal: {
          enabled: true,
          width: '81.5%',
        },
      },
      {
        path: ROUTES.DESTINATIONS_SHARE_DATA.path,
        component: loadable(ROUTES.DESTINATIONS_SHARE_DATA.component, { fallback }),
        group: DataPipelinePageVariation.DESTINATION,
        modal: {
          enabled: true,
          width: '81.5%',
        },
      },
    ]
  },
  {
    path: ROUTES.DESTINATIONS_CATALOG.path,
    component: loadable(ROUTES.DESTINATIONS_CATALOG.component, { fallback }),
    group: DataPipelinePageVariation.DESTINATION,
    exact: true,
    routes: [
      {
        path: ROUTES.DESTINATIONS_DATA_FORM.path,
        component: loadable(ROUTES.DESTINATIONS_DATA_FORM.component, { fallback }),
        group: DataPipelinePageVariation.DESTINATION,
        modal: {
          enabled: true,
          width: '81%',
          callbackURL: ROUTES.DESTINATIONS_DATA_CONNECT.path,
        },
      },
      {
        path: ROUTES.DESTINATIONS_DATA_CONNECT.path,
        component: loadable(ROUTES.DESTINATIONS_DATA_CONNECT.component, { fallback }),
        group: DataPipelinePageVariation.DESTINATION,
        modal: {
          enabled: true,
          width: '81%',
          callbackURL: ROUTES.DESTINATIONS_CATALOG.path,
        },
      },
    ]
  },
]
