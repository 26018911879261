export enum FrequencyEnum {
  EVERY_15_MINUTES = 'EVERY_15_MIN',
  EVERY_30_MINUTES = 'EVERY_30_MIN',
  EVERY_DAY_0AM = 'EVERY_DAY_0AM',
  EVERY_DAY_2AM = 'EVERY_DAY_2AM',
  EVERY_WEEKDAY = 'EVERY_WEEKDAY',
  EVERY_NIGHT_RANDOM = 'EVERY_NIGHT_RANDOM',
  EVERY_SATURDAY = 'EVERY_SATURDAY',
  EVERY_SATURDAY_NIGHT_RANDOM = 'EVERY_SATURDAY_NIGHT_RANDOM',
  EVERY_MONTH = 'EVERY_MONTH',
  EVERY_MONTH_RANDOM = 'EVERY_MONTH_RANDOM',
  EVERY_3_HOURS = 'EVERY_3_HOURS',
  EVERY_HOUR = 'EVERY_HOUR',
  NEVER = 'NEVER',
}
