import { SET_PROPERTY } from './action-types';
import initialState from './initial-state';

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PROPERTY: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
