import { ROUTES } from '@/shared/constants/navigation';
import loadable from '@loadable/component';
import { DataPipelinePageVariation } from '@/shared/constants/dataPipelines';
import { fallback } from '@/routes/index';

// prettier-ignore
export default [
  {
    path: ROUTES.DATA_PIPELINE_WORKFLOWS_DETAILS.path,
    component: loadable(ROUTES.DATA_PIPELINE_WORKFLOWS_DETAILS.component, { fallback }),
    group: DataPipelinePageVariation.WORKFLOW,
    routes: [
      {
        path: ROUTES.DATA_PIPELINE_WORKFLOWS_DETAILS_EDIT.path,
        component: loadable(ROUTES.DATA_PIPELINE_WORKFLOWS_DETAILS_EDIT.component, { fallback }),
        group: DataPipelinePageVariation.WORKFLOW,
        modal: {
          enabled: true,
          width: '81.5%',
        },
      },
      {
        path: ROUTES.DATA_PIPELINE_WORKFLOWS_DETAILS_SHARE.path,
        component: loadable(ROUTES.DATA_PIPELINE_WORKFLOWS_DETAILS_SHARE.component, { fallback }),
        group: DataPipelinePageVariation.WORKFLOW,
        modal: {
          enabled: true,
          width: '81.5%',
        },
      },
    ],
  },
  {
    path: ROUTES.DATA_PIPELINE_WORKFLOWS.path,
    component: loadable(ROUTES.DATA_PIPELINE_WORKFLOWS.component, { fallback }),
    group: DataPipelinePageVariation.WORKFLOW,
    routes: [
      {
        path: ROUTES.DATA_PIPELINE_WORKFLOWS_CREATE.path,
        component: loadable(ROUTES.DATA_PIPELINE_WORKFLOWS_CREATE.component, { fallback }),
        group: DataPipelinePageVariation.WORKFLOW,
        modal: {
          enabled: true,
          width: '81.5%',
          callbackURL: ROUTES.DATA_PIPELINE_WORKFLOWS.path,
        },
      },
    ]
  },
];
