import {
  signIn as service,
  signOut as serviceSignout,
} from '../../../services/manager';
import {
  SIGN_OUT,
  HANDLE_UNAUTHORIZED_ACCESS,
  SIGN_IN_FAIL,
  SIGN_IN_SUCCESS,
} from '../action-types';
import { SET_PROPERTY } from '../../properties/action-types';
import { push } from 'connected-react-router';
import { ROUTES } from '@/shared/constants/navigation';
import { getStorage } from '@/shared/hooks/app';

export const signInStart = (payload) => (dispatch) =>
  service(payload, dispatch);

export const signInSuccess = (payload, propertiesPayload) => (dispatch) => {
  dispatch({ type: SIGN_IN_SUCCESS, payload });
  dispatch({ type: SET_PROPERTY, payload: propertiesPayload });
  dispatch(push(ROUTES.ROOT_LOGGED.path));
};

export const signInFail = (payload) => {
  return {
    type: SIGN_IN_FAIL,
    payload,
  };
};

export const signOut = (isUnauthorizedAccess) => (dispatch) => {
  serviceSignout().then(async () => {
    const { store } = getStorage();
    const signInDestination = await store.getItem('signInDestination');

    const signPath = ROUTES.SIGN_IN.path;

    await store.clear();

    /**
     * Other components set signInDestination when they want to redirect after a user logs in. Since multiple redirects
     * are possible within the app, we are only saving the last two to cap the memory usage and history disclosure.
     **/
    if (signInDestination && Array.isArray(signInDestination)) {
      await store.setItem('signInDestination', signInDestination.slice(-2));
    }

    if (!window.location?.href.endsWith('sign-in')) {
      dispatch(push(signPath));
      if (isUnauthorizedAccess) {
        dispatch({ type: HANDLE_UNAUTHORIZED_ACCESS, payload: {} });
      } else {
        dispatch({ type: SIGN_OUT, payload: {} });
      }
    }
  });
};

export const handleIncompleteProfile = () => (dispatch) =>
  dispatch(push(ROUTES.MY_ACCOUNT_PROFILE_SETUP.path));

export const handleUnauthorizedAccess = () => (dispatch) =>
  signOut(true)(dispatch);
