import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from './styles';
import { Col, Row } from '@baosystems/nebula';
import ProfileMenu from '../ProfileMenu';
import { LogoAnalytics, LogoBao } from '@/shared/assets/svg';
import { Link } from 'react-router-dom';
import { ROUTES } from '@/shared/constants/navigation';
import AppSelector from '@/shared/components/AppSelector';
import {
  APPS_MANAGER_URL,
  APPS_MANAGER_USER_URL,
} from '@/shared/constants/api';
import LayoutContentWrapper from '@/shared/components/Layout/components/LayoutContentWrapper';
import { useParams } from 'react-router';
import { isPepfar } from '@/shared/services/domainProvider.service';
import { PepfarLogo } from '@/shared/assets/pepfar/pepfarLogo.component';
import { useRoles } from '@/shared/hooks/profile';
import { UserRolesEnum } from '@/shared/interfaces/user';

const Container = styled(Row)(styles.container);
const LogoContainer = styled(Col)(styles.logoContainer);
const ProfileContainer = styled(Row)(styles.profile);
const Nav = styled(Col)(styles.nav);

function HeaderLogoProvider(isLogged: boolean) {
  if (isPepfar()) return <PepfarLogo />;
  if (isLogged) return <LogoAnalytics />;
  return <LogoBao />;
}

const Header = ({
  headerNavigation,
  signOut,
  isLogged,
  showAppSelector,
}: any) => {
  const { domain = '' }: any = useParams();
  const { validateRoles } = useRoles();
  const isUserManager =
    isLogged && validateRoles([UserRolesEnum.ROLE_MANAGE_USER]);
  const signInPath =
    domain || !showAppSelector
      ? ROUTES.SIGN_IN_COGNITO.path.replace(':domain', domain)
      : ROUTES.SIGN_IN.path;

  const availableLinks = [
    {
      label: 'Analytics Platform',
      key: 'analytics-platform',
      url: `${ROUTES.PUBLIC_URL.path}/${ROUTES.BASE.path}${ROUTES.ROOT_LOGGED.path}`,
    },
    {
      label: 'Manager',
      key: 'manager',
      url: APPS_MANAGER_URL,
    },
    ...(isUserManager
      ? [
          {
            label: 'Users',
            key: 'userManagement',
            url: APPS_MANAGER_USER_URL,
          },
        ]
      : []),
  ];

  return (
    <Container
      type="flex"
      justify="space-between"
      align="middle"
      $light={!isLogged || undefined}
      className={'css_header'}
    >
      {isLogged ? (
        <>
          <LogoContainer span={12}>
            <Link to={ROUTES.ROOT_LOGGED.path} className="logo-link">
              {HeaderLogoProvider(isLogged)}
            </Link>
          </LogoContainer>
          <Nav>
            {showAppSelector && (
              <AppSelector
                items={availableLinks}
                defaultSelectedKeys="analytics-platform"
              />
            )}
            <ProfileContainer type="flex" justify="end">
              <ProfileMenu methods={{ headerNavigation, signOut }} />
            </ProfileContainer>
          </Nav>
        </>
      ) : (
        <LayoutContentWrapper>
          <LogoContainer span={12} offset={1}>
            <Link to={signInPath} className="logo-link">
              {HeaderLogoProvider(isLogged)}
            </Link>
          </LogoContainer>
        </LayoutContentWrapper>
      )}
    </Container>
  );
};

Header.defaultProps = {
  showAppSelector: true,
};

Header.propTypes = {
  isLogged: PropTypes.bool,
  profile: PropTypes.shape({
    user: PropTypes.shape({
      username: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
  headerNavigation: PropTypes.object,
  showAppSelector: PropTypes.bool,
  signOut: PropTypes.func,
};

export default Header;
