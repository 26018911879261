import { ROUTES } from '@/shared/constants/navigation';
import loadable from '@loadable/component';
import { DataPipelinePageVariation } from '@/shared/constants/dataPipelines';
import { fallback } from '@/routes/index';
// prettier-ignore
export default [
  {
    path: ROUTES.DATA_PIPELINE_SCHEMAS_DETAILS.path,
    component: loadable(ROUTES.DATA_PIPELINE_SCHEMAS_DETAILS.component, { fallback }),
    group: DataPipelinePageVariation.SCHEMA,
    routes: [
      {
        path: ROUTES.DATA_PIPELINE_SCHEMAS_DETAILS_EDIT.path,
        component: loadable(ROUTES.DATA_PIPELINE_SCHEMAS_DETAILS_EDIT.component, { fallback }),
        group: DataPipelinePageVariation.SCHEMA,
        modal: {
          enabled: true,
          width: '81.5%',
        },
      },
    ],
  },
  {
    path: ROUTES.DATA_PIPELINE_SCHEMAS.path,
    component: loadable(ROUTES.DATA_PIPELINE_SCHEMAS.component, { fallback }),
    group: DataPipelinePageVariation.SCHEMA,
    routes: [
      {
        path: ROUTES.DATA_PIPELINE_SCHEMAS_CREATE.path,
        component: loadable(ROUTES.DATA_PIPELINE_SCHEMAS_CREATE.component, { fallback }),
        group: DataPipelinePageVariation.SCHEMA,
        modal: {
          enabled: true,
          width: '81.5%',
          callbackURL: ROUTES.DATA_PIPELINE_SCHEMAS.path,
        }
      },
    ]
  }
];
