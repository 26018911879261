import { css } from 'styled-components';

const mobile = ({ theme }) => css`
  display: none;
  flex-wrap: wrap;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: calc(100% - 64px);
  background-color: #f8f9fa;
  z-index: 500;

  && {
    .ant-menu {
      flex: 1;

      .ant-menu-item,
      .ant-menu-submenu-title {
        color: ${theme.tokens.COLOR_BRAND_500};
      }
    }
  }

  .menu-content {
    flex: 1;
    width: 100%;
    align-self: flex-end;
    overflow-y: auto;
  }

  .menu-footer {
    width: 100%;
    align-self: flex-end;
    background-color: ${theme.tokens.COLOR_BRAND_050};

    .ant-menu-inline .ant-menu-item::after {
      right: initial;
      left: 0;
    }

    .ant-menu-item {
      height: 30px;
      &:last-child {
        margin-bottom: 20px;
      }
      &.ant-menu-item-selected {
        color: ${theme.tokens.COLOR_BRAND_050};
        background-color: ${theme.tokens.COLOR_BRAND_050};
      }
    }
  }
`;

const styles = {
  component: ({ isMobile }) => css`
    width: 100%;
    && {
      ${isMobile && mobile};

      .menu-content {
        display: flex;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        > ul {
          padding-top: 28px;
          border-color: transparent;
        }
      }

      && {
        .ant-menu-item {
          &:first-child {
            margin-top: 0;
          }
          &.ant-menu-item-only-child {
            margin: 0;
          }
        }
      }
    }
  `,
};

export default styles;
