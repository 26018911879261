import { LOCATION_CHANGE } from 'connected-react-router';

const rootRoute = process.env.REACT_APP_ROOT_ROUTER || '';

const eventsMap = {
  [LOCATION_CHANGE]: (action) => ({
    hitType: 'Pageview',
    pagePath: `${rootRoute}${action.payload.location.pathname}`,
  }),
};

export default eventsMap;
