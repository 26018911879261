import { useRef, useState } from 'react';
import styled from 'styled-components';
import { Menu, Popover, Text, View } from '@baosystems/nebula';
import { AppsIcon } from '@/shared/assets/svg';
import styles from './styles';

const Container = styled(View)(styles.container);
const MenuContainer = styled(View)(styles.menuContainer);
const Title = styled(View)(styles.title);

interface AppSelectorProps {
  items: Array<AppSelectorItem>;
  defaultSelectedKeys?: string | Array<string>;
  title?: string;
}

interface AppSelectorItem {
  label: string;
  key: string;
  url: string;
  target?: '_blank' | '_self';
}

const AppSelector = ({
  title,
  defaultSelectedKeys,
  items,
}: AppSelectorProps) => {
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const selectedKeys =
    typeof defaultSelectedKeys === 'string'
      ? [defaultSelectedKeys]
      : defaultSelectedKeys;

  const handleVisibilityChange = (isOpen: boolean) => setOpen(isOpen);

  const content = (
    <MenuContainer>
      {title && <Title>{title}</Title>}
      <Menu defaultSelectedKeys={selectedKeys} mode="inline" selectable={false}>
        {items.map((itemProps: AppSelectorItem) => (
          <Menu.Item
            key={itemProps.key}
            active={selectedKeys?.some((key) => key === itemProps.key)}
          >
            <a href={itemProps.url} target={itemProps.target}>
              <Text>{itemProps.label}</Text>
            </a>
          </Menu.Item>
        ))}
      </Menu>
    </MenuContainer>
  );

  return (
    <Container className="app-selector">
      <div ref={containerRef} className="selector-container">
        <Popover
          trigger="click"
          content={content}
          arrowPointAtCenter
          placement="bottom"
          getPopupContainer={() => containerRef.current}
          onVisibleChange={handleVisibilityChange}
        >
          <AppsIcon className={`apps-icon ${open ? 'active' : ''}`} />
        </Popover>
      </div>
    </Container>
  );
};

export default AppSelector;
