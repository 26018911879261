import { ROUTES } from '@/shared/constants/navigation';
import loadable from '@loadable/component';
import { DataPipelinePageVariation } from '@/shared/constants/dataPipelines';
import { fallback } from '@/routes/index';

// prettier-ignore
export default [
  {
    path: ROUTES.DATA_PIPELINE_VIEWS_DETAILS.path,
    component: loadable(ROUTES.DATA_PIPELINE_VIEWS_DETAILS.component, { fallback }),
    group: DataPipelinePageVariation.VIEWS,
    routes: [
      {
        path: ROUTES.DATA_PIPELINE_VIEWS_DETAILS_SHARE_DATA.path,
        component: loadable(ROUTES.DATA_PIPELINE_VIEWS_DETAILS_SHARE_DATA.component, { fallback }),
        group: DataPipelinePageVariation.VIEWS,
        modal: {
          enabled: true,
          width: '81.5%',
        },
      },
      {
        path: ROUTES.DATA_PIPELINE_VIEWS_DETAILS_EDIT.path,
        component: loadable(ROUTES.DATA_PIPELINE_VIEWS_DETAILS_EDIT.component, { fallback }),
        group: DataPipelinePageVariation.VIEWS,
        modal: {
          enabled: true,
          width: '81.5%',
        },
      },
      {
        path: ROUTES.DATA_PIPELINE_VIEWS_DETAILS_DUPLICATE.path,
        component: loadable(ROUTES.DATA_PIPELINE_VIEWS_DETAILS_DUPLICATE.component, { fallback }),
        group: DataPipelinePageVariation.VIEWS,
        isDuplicate: true,
        modal: {
          enabled: true,
          width: '81.5%',
        },
      },
      {
          path: ROUTES.DATA_PIPELINE_VIEWS_DETAILS_DOWNLOAD.path,
          component: loadable(ROUTES.DATA_PIPELINE_VIEWS_DETAILS_DOWNLOAD.component, { fallback }),
          group: DataPipelinePageVariation.VIEWS,
          modal: {
              enabled: true,
              width: '81.5%',
          },
      },
      {
        path: ROUTES.DATA_PIPELINE_VIEWS_SQL_EDITOR.path,
        component: loadable(ROUTES.DATA_PIPELINE_VIEWS_SQL_EDITOR.component, { fallback }),
        group: DataPipelinePageVariation.VIEWS,
        modal: {
          enabled: true,
          width: '100%'
        }
      }
    ],
  },
  {
    path: ROUTES.DATA_PIPELINE_VIEWS.path,
    component: loadable(ROUTES.DATA_PIPELINE_VIEWS.component, { fallback }),
    group: DataPipelinePageVariation.VIEWS,
    routes: [
      {
        path: ROUTES.DATA_PIPELINE_VIEWS_CREATE.path,
        component: loadable(ROUTES.DATA_PIPELINE_VIEWS_CREATE.component, { fallback }),
        group: DataPipelinePageVariation.VIEWS,
        modal: {
          enabled: true,
          width: '81.5%',
          callbackURL: ROUTES.DATA_PIPELINE_VIEWS.path,
        },
      },
    ]
  },
];
