import loadable from '@loadable/component';
import { ROUTES } from '@/shared/constants/navigation';
import App from '../App/index';
import Layout from '@/shared/components/Layout/index';
import { Route } from '@/shared/interfaces/navigation';
import { Spinner } from '@baosystems/nebula';
import { Redirect } from 'react-router';
import views from './views';
import schema from './schema';
import dataQualityChecks from './dataQualityChecks';
import dataQualityCheckGroups from './dataQualityCheckGroups';
import datasets from './datasets';
import destinations from './destinations';
import workflows from './workflows';
import myAccount from './my-account';

export const fallback = <Spinner size="normal" />;

// prettier-ignore

const routes: Array<Route> = [
  {
    component: App,
    routes: [

// PRIVATE ROUTES ------------------------------------------------------------------------------------------------------

      // MY ACCOUNT
      {
        component: Layout,
        path: ROUTES.MY_ACCOUNT_PROFILE_SETUP.path,
        rules: { isPrivate: false },
        lightBG: true,
        exact: true,
        props: {
          showMenu: false
        },
        routes: [
          {
            lightBG: true,
            component: loadable(ROUTES.MY_ACCOUNT_PROFILE_SETUP.component),
          },
        ]
      },

      {
        path: ROUTES.MY_ACCOUNT.path,
        component: Layout,
        rules: { isPrivate: true },
        props: {
          showCredits: true
        },
        routes: [
          ...myAccount,
        ]
      },



      // ANALYTICS PLATFORM
      {
        path: [
          ROUTES.DATA_PIPELINE.path,
          ROUTES.ABOUT.path,
          ROUTES.DATA_PIPELINE_DOWNLOAD_PROXY.path,
          ROUTES.SQL_EDITOR.path,
          ROUTES.VARIABLES.path,
          ROUTES.FIREWALL_RULES.path,
          ROUTES.SETTINGS.path,
        ],
        component: Layout,
        rules: { isPrivate: true },
        props: {
          showCredits: true
        },
        routes: [
          ...views,
          ...dataQualityChecks,
          ...dataQualityCheckGroups,
          ...schema,
          ...datasets,
          ...destinations,
          ...workflows,
          {
            path: ROUTES.SQL_EDITOR.path,
            component: loadable(ROUTES.SQL_EDITOR.component, {fallback}),
          },
          {
            path: ROUTES.DATA_PIPELINE_CONNECTION_INFO.path,
            component: loadable(ROUTES.DATA_PIPELINE_CONNECTION_INFO.component, {fallback}),
          },
          {
            path: ROUTES.ABOUT_ANALYTICS.path,
            component: loadable(ROUTES.ABOUT_ANALYTICS.component, {fallback}),
          },
          {
            path: ROUTES.DATA_PIPELINE_DOWNLOAD_PROXY.path,
            component: loadable(ROUTES.DATA_PIPELINE_DOWNLOAD_PROXY.component),
          },
          {
            path: ROUTES.VARIABLES.path,
            component: loadable(ROUTES.VARIABLES.component),
          },
          {
            path: ROUTES.FIREWALL_RULES.path,
            component: loadable(ROUTES.FIREWALL_RULES.component),
          },
          {
            path: ROUTES.SETTINGS.path,
            component: loadable(ROUTES.SETTINGS.component),
          },

       // LANDING-PAGES
          {
            path: ROUTES.DATA_PIPELINE.path,
            component: () => <Redirect to={ROUTES.DATA_PIPELINE_DATA_CATALOG.path}/>
          },

        ]
      },

      // VISUALIZATION
      {
        path: ROUTES.DATA_PIPELINE_VISUALIZATION.path,
        component: loadable(ROUTES.DATA_PIPELINE_VISUALIZATION.component),
      },

// PUBLIC ROUTES -------------------------------------------------------------------------------------------------------

      {
        path: ROUTES.ROOT.path,
        component: () => <Redirect to={ROUTES.DATA_PIPELINE_DATA_CATALOG.path} />,
        rules: { isPrivate: true },
        exact: true,
      },
      {
        path: ROUTES.ROOT.path,
        component: Layout,
        rules: { isPrivate: false },
        lightBG: true,
        exact: false,
        routes: [
          {
            path: ROUTES.SIGN_IN_COGNITO.path,
            exact: true,
            component: loadable(ROUTES.SIGN_IN_COGNITO.component)
          },
          {
            path: ROUTES.SIGN_UP_COGNITO.path,
            exact: true,
            component: loadable(ROUTES.SIGN_UP_COGNITO.component)
          },
          {
            path: ROUTES.AUTHENTICATION_MFA.path,
            exact: true,
            component: loadable(ROUTES.AUTHENTICATION_MFA.component),
            lightBG: true
          },
          {
            path: [ROUTES.AUTHENTICATION_NEW_PASSWORD.path, ROUTES.AUTHENTICATION_INVITATION.path],
            exact: true,
            component: loadable(ROUTES.AUTHENTICATION_NEW_PASSWORD.component),
            lightBG: true
          },
          {
            path: ROUTES.AUTHENTICATION_CONFIRM_SIGN_UP.path,
            exact: true,
            component: loadable(ROUTES.AUTHENTICATION_CONFIRM_SIGN_UP.component),
            lightBG: true
          },
          {
            path: [ROUTES.SIGN_IN.path, ""],
            exact: true,
            component: loadable(ROUTES.SIGN_IN.component)
          },
        ]
      },
    ]
  }
];

export default routes;
