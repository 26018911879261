import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import { createMetaReducer } from 'redux-beacon';
import eventsMap from './event-definitions';
import properties from './properties/reducers.js';
import profile from './profile/reducers';

const gtmMetaReducer = createMetaReducer(eventsMap, GoogleTagManager());
const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    gtmMetaReducer,
    properties,
    profile,
  });

export default createRootReducer;
