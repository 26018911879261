import { ROUTES } from '@/shared/constants/navigation';
import loadable from '@loadable/component';
import { DataPipelinePageVariation } from '@/shared/constants/dataPipelines';
import { fallback } from '@/routes/index';

// prettier-ignore
export default [
  {
    path: ROUTES.DATA_PIPELINE_DATA_QUALITY_CHECK_GROUPS_DETAILS.path,
    component: loadable(ROUTES.DATA_PIPELINE_DATA_QUALITY_CHECK_GROUPS_DETAILS.component, { fallback }),
    group: DataPipelinePageVariation.DATA_QUALITY_CHECK_GROUP,
    routes: [
      {
        path: ROUTES.DATA_PIPELINE_DATA_QUALITY_CHECK_GROUPS_DETAILS_EDIT.path,
        component: loadable(ROUTES.DATA_PIPELINE_DATA_QUALITY_CHECK_GROUPS_DETAILS_EDIT.component, { fallback }),
        group: DataPipelinePageVariation.DATA_QUALITY_CHECK_GROUP,
        modal: {
          enabled: true,
          width: '81.5%',
        },
      }
    ],
  },
  {
    path: ROUTES.DATA_PIPELINE_DATA_QUALITY_CHECK_GROUPS.path,
    component: loadable(ROUTES.DATA_PIPELINE_DATA_QUALITY_CHECK_GROUPS.component, { fallback }),
    group: DataPipelinePageVariation.DATA_QUALITY_CHECK_GROUP,
    routes: [
      {
        path: ROUTES.DATA_PIPELINE_DATA_QUALITY_CHECK_GROUPS_CREATE.path,
        component: loadable(ROUTES.DATA_PIPELINE_DATA_QUALITY_CHECK_GROUPS_CREATE.component, { fallback }),
        group: DataPipelinePageVariation.DATA_QUALITY_CHECK_GROUP,
        modal: {
          enabled: true,
          width: '81.5%',
          callbackURL: ROUTES.DATA_PIPELINE_DATA_QUALITY_CHECK_GROUPS.path,
        },
      },
    ]
  },
];
