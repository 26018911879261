export enum Dhis2SourceParamsEnum {
  aggregateData = 'AGGREGATE',
  program = 'PROGRAM',
  metadata = 'METADATA',
  audit = 'AUDIT',
}

export enum DHIS2PeriodTypesEnum {
  DAYS = 'Days',
  WEEKS = 'Weeks',
  MONTHS = 'Months',
  YEARS = 'Years',
}
