import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import { createMiddleware as createGTMMiddleware } from 'redux-beacon';
import createRootReducer from './root-reducers';
import eventsMap from './event-definitions';
import * as Sentry from '@sentry/react';
import createSentryMiddleware from 'redux-sentry-middleware';
import apiMiddleware from '../utils/middleware/api';
import { createHashHistory } from 'history';

export const history = createHashHistory({ hashType: 'slash' });

Sentry.init({
  dsn:
    'https://55c3bc0f960a464faebc154ffbc9c299@o270840.ingest.sentry.io/5219697',
  release: process.env.REACT_APP_VERSION || process.env.PACKAGE_VERSION,
  environment: process.env.ENVIRONMENT || 'production',
});

const gtmMiddleware = createGTMMiddleware(eventsMap, GoogleTagManager());
const middleWares = [
  thunk,
  routerMiddleware(history),
  gtmMiddleware,
  apiMiddleware,
  createSentryMiddleware(Sentry),
];

export default function configureStore(preloadedState) {
  return createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(applyMiddleware(...middleWares))
  );
}
