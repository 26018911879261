import {
  SIGN_IN_START,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAIL,
  SIGN_OUT,
  HANDLE_UNAUTHORIZED_ACCESS,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
} from './action-types';
import initialState from './initial-state';
import { COMPONENT_STATUS } from '../../constants';

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_START || GET_PROFILE_REQUEST:
      return {
        logged: false,
        status: COMPONENT_STATUS.LOADING,
        data: action.payload,
      };
    case SIGN_IN_SUCCESS || GET_PROFILE_SUCCESS:
      return {
        logged: true,
        status: COMPONENT_STATUS.LOADED,
        data: action.payload,
      };
    case SIGN_IN_FAIL || GET_PROFILE_FAIL:
      return {
        logged: false,
        status: COMPONENT_STATUS.ERROR,
        data: action.payload,
      };
    case SIGN_OUT || HANDLE_UNAUTHORIZED_ACCESS:
      return initialState;
    default:
      return state;
  }
};
