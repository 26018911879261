import {
  API_BASE_URL,
  API_PATH_INVITATION,
  API_PATH_INVITATION_VALIDATION,
  API_PATH_LOGIN,
  API_PATH_LOGOUT,
  API_PATH_MANAGER_INFO,
  API_PATH_SCHEMAS,
  API_PATH_SIGNUP,
  API_PATH_USER_RESET_PASSWORD,
} from '../constants/api';
import { stringify } from '@/shared/utils';
import { UserInfo } from '@/shared/interfaces/user';
import { handleAccess, showIfError } from '@/shared/utils/api-utils';
import { COMPONENT_STATUS } from '@/shared/constants';
import { generatePath } from 'react-router-dom';
// import {setDomain} from "@/shared/services/domainProvider.service";

const controller = new AbortController();
const signal = controller.signal;

/**
 * @method getManagerInfo
 * @description Fetch manager user info
 * @returns {Promise<any>}
 */
export const getManagerInfo = (_dispatch?: any, auth?: { token: string }) => {
  return fetch(API_BASE_URL + API_PATH_MANAGER_INFO, {
    credentials: 'include',
    signal,
    redirect: 'error',
    headers: auth
      ? new Headers({
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        })
      : {
          'Content-Type': 'application/json',
        },
  })
    .then(handleAccess)
    .then(showIfError)
    .then(async (res) => {
      const payload: UserInfo = await res.json();
      return payload;
    });
};

/**
 * @method signIn
 * @param username
 * @param password
 * @param code
 * @param dispatch
 * @description sign-in application
 * @returns {Promise<any>}
 */
export const signIn = ({
  username,
  password,
  code = '',
}: {
  username: string;
  password: string;
  code?: string;
}) => {
  return fetch(`${API_BASE_URL}${API_PATH_LOGIN}`, {
    method: 'post',
    credentials: 'include',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: stringify({
      username: encodeURIComponent(username),
      password: encodeURIComponent(password),
      code,
      redirect: 0,
    }),
  })
    .then(showIfError)
    .then((res) => res.json())
    .then((data) => {
      if (data && data.messageKey !== COMPONENT_STATUS.SUCCESS) {
        throw new Error(data.message);
      }
      return data;
    });
};

/**
 * @method signUp
 * @param email
 * @description sign-up application
 * @returns {Promise<any>}
 */
export const signUp = async ({ email }: { email: string }) => {
  return await fetch(`${API_BASE_URL}${API_PATH_SIGNUP}`, {
    method: 'POST',
    body: JSON.stringify({ email }),
    credentials: 'include',
    headers: {
      'content-type': 'application/json;charset=UTF-8',
    },
  })
    .then(showIfError)
    .then(async (res) => {
      if (res.status !== 201) {
        const data = await res.json();
        if (data?.statusCode >= 400) {
          throw new Error(data.message);
        }
      }
      return res;
    });
};

/**
 * @method signOut
 *
 * @return {Promise<Response>}
 */
export const signOut = () =>
  fetch(`${API_BASE_URL}${API_PATH_LOGOUT}`, {
    credentials: 'include',
    redirect: 'manual',
  }).then(showIfError);

/**
 * @method userResetPassword
 * @param {string} username
 * @return {Promise<Response>}
 */
export const userResetPassword = ({ username }: { username: string }) => {
  return fetch(
    `${API_BASE_URL}${generatePath(API_PATH_USER_RESET_PASSWORD, {
      username,
    })}`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
    }
  ).then(showIfError);
};

/**
 * @method userInvitation
 * @param {string} userId
 * @param {string} code
 * @param {string} password
 * @return {Promise<Response>}
 */
export const userInvitation = ({
  userId,
  code,
  password,
}: {
  userId: string;
  code: string;
  password: string;
}) => {
  return fetch(
    `${API_BASE_URL}${generatePath(API_PATH_INVITATION, {
      userId,
    })}`,
    {
      method: 'POST',
      body: JSON.stringify({ code, password }),
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
    }
  )
    .then(showIfError)
    .then(async (res) => {
      if (res.status !== 201) {
        const data = await res.json();
        if (data?.statusCode >= 400) {
          throw new Error(data.message);
        }
      }
      return res;
    });
};

/**
 * @method validateUserInvitation
 * @param {string} userId
 * @param {string} code
 * @return {Promise<Response>}
 */
export const validateUserInvitation = ({
  userId,
  code,
}: {
  userId: string;
  code: string;
}) => {
  return fetch(
    `${API_BASE_URL}${generatePath(API_PATH_INVITATION_VALIDATION, {
      userId,
      code,
    })}`
  )
    .then(showIfError)
    .then((res) => res.json())
    .then((data) => {
      if (data.status === 'ERROR') {
        throw new Error(data.message);
      }

      return data;
    });
};

/**
 * @method getSchemas
 * @description Fetch all available table schemas.
 * @returns {Promise<any>}
 */
export const getSchemas = () =>
  fetch(API_BASE_URL + API_PATH_SCHEMAS, {
    credentials: 'include',
  })
    .then(handleAccess)
    .then(showIfError)
    .then((res) => res.json());
