import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Header from '@/shared/components/Header';
import Menu from '@/shared/components/Menu';
import Loader from '@/shared/components/Loader/Loader';
import LayoutRoutes from './components/LayoutRoutes';
import { COMPONENT_STATUS_LIST, THEMES_LIST } from '@/shared/constants';
import { NavigationContextEnum } from '@/shared/interfaces/ui';
import { ToastContainer, Slide } from 'react-toastify';
import { Close } from '@/shared/assets/icons';
import 'react-toastify/dist/ReactToastify.css';
import { getHeaderNavigation } from './utils';
import GlobalStyles from './globalStyles';

import styles from './styles';
import { useTranslation } from 'react-i18next';

const Container = styled<any>('div')(styles.container);
const Sidebar = styled<any>('div')(styles.sidebar);
const SidebarFooter = styled<any>('div')(styles.sidebarFooter);
const Main: any = styled<any>('div')(styles.main);
const Content: any = styled<any>('div')(styles.contentContainer);
const LoaderContainer = styled<any>('div')(styles.loaderContainer);
const StylishToast = styled(ToastContainer).attrs({
  className: 'toast-container',
})(styles.toastContainer);

const CloseButton = ({
  closeToast,
}: {
  closeToast: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}) => (
  <div className="close-toast">
    <Close className="material-icons" onClick={closeToast} />
  </div>
);

const Layout = (props: any): React.FunctionComponentElement<any> => {
  const {
    showHeader,
    showMenu,
    showLoader,
    loader,
    signOut,
    route,
    profile,
    menuItems,
    handleRouteAccess = () => true,
  }: any = props;

  // Check route access
  useEffect(() => {
    handleRouteAccess({ rules: route.rules, isLogged: profile?.logged });
  }, [route.rules, profile, handleRouteAccess]);

  const { t } = useTranslation();
  const headerNavigation = getHeaderNavigation({ t });

  const showCredits = route?.props?.showCredits;

  return (
    <>
      <GlobalStyles />
      <Container id={'root-layout'}>
        {showHeader && (
          <Header
            profile={profile?.data}
            headerNavigation={headerNavigation}
            signOut={signOut}
            isLogged={profile?.logged}
            showAppSelector={true}
          />
        )}
        <Main>
          {showMenu && (
            <Sidebar>
              <Menu items={menuItems} />
              {showCredits && (
                <SidebarFooter>
                  {t('common:components.footer.credits')}
                </SidebarFooter>
              )}
            </Sidebar>
          )}
          <Content className="custom-scroll css_mainLayout">
            <LayoutRoutes routes={route.routes} />
            {showLoader && (
              <LoaderContainer>
                <Loader {...loader} />
              </LoaderContainer>
            )}
          </Content>
        </Main>
        <StylishToast
          position="bottom-center"
          autoClose={5000}
          transition={Slide}
          hideProgressBar
          pauseOnHover
          theme="colored"
          //@ts-ignore
          closeButton={CloseButton}
        />
      </Container>
    </>
  );
};

Layout.propTypes = {
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  showMenu: PropTypes.bool,
  menuItems: PropTypes.array,
  theme: PropTypes.shape({
    name: PropTypes.oneOf(THEMES_LIST),
    brand: PropTypes.oneOf(THEMES_LIST),
  }),
  showLoader: PropTypes.bool,
  loader: PropTypes.shape({
    status: PropTypes.oneOf(COMPONENT_STATUS_LIST),
    title: PropTypes.string,
    label: PropTypes.string,
    progress: PropTypes.number,
  }),
  signOut: PropTypes.func,
  handleRouteAccess: PropTypes.func,
  route: PropTypes.object,
  history: PropTypes.object,
  navigationContext: PropTypes.string,
  props: PropTypes.shape({ sideBarFooter: PropTypes.any }),
};

Layout.defaultProps = {
  showHeader: true,
  showFooter: false,
  showMenu: true,
  showLoader: false,
  navigationContext: NavigationContextEnum.dataPipeline,
};

export default Layout;
