import { css } from 'styled-components';
import { HEADER_HEIGHT } from '@/shared/constants/ui';

const styles = {
  container: () => css`
    display: flex;
    flex-wrap: nowrap;
    height: ${(props) => (props.light ? '76px' : HEADER_HEIGHT + 'px')};
    width: 100%;
    background-color: ${(props) =>
      props.$light
        ? props.theme.tokens.COLOR_NEUTRAL_WHITE
        : props.theme.tokens.COLOR_BRAND_900};
    padding: 0 ${(props) => props.theme.tokens.SPACING_NORMAL_MAX}px;
  `,
  logoContainer: () => css`
    flex: 1;
    min-width: 186px;
    margin-right: ${(props) => props.theme.tokens.SPACING_NORMAL_BASE}px;
    padding-top: 5px;
    justify-self: flex-start;
    align-items: center;
    align-content: center;
    white-space: nowrap;
    display: flex;

    .app-selector + .logo-link {
      margin-left: 16px;
    }

    svg {
      transition: opacity 200ms ease-in-out;
      cursor: pointer;
      opacity: 1;
      &:hover {
        opacity: 0.8;
      }
    }

    .logo-text,
    .logo-icon {
      fill: #fff;
    }
  `,
  navigationContainer: () => css`
    justify-self: flex-end;
  `,
  nav: () => css`
    margin-left: ${(props) => props.theme.tokens.SPACING_SMALL_MAX * 2}px;
    justify-self: flex-end;
    align-items: center;
    display: flex;
    gap: 30px;
  `,
  profile: () => css`
    && {
      width: fit-content;
    }
  `,
};

export default styles;
