import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';
import styled from 'styled-components/macro';
import styles from './styles';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@/shared/utils/hooks';
import { ThemeProvider } from 'styled-components';
import { THEMES_LIST } from '@/shared/constants';
import { useEffect, useMemo } from 'react';
import Amplify from 'aws-amplify';
import { awsConfig } from '@/shared/services/aws-amplify';

const Container = styled('div')(styles.container);

const App = ({ theme, route }) => {
  const { t } = useTranslation();
  const appTheme = useTheme(theme);
  const routes = useMemo(() => renderRoutes(route.routes), [route.routes]);

  useEffect(() => {
    Amplify.configure(awsConfig);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {t('common:components.head.title', {
            page: t('dataPipeline:catalog.title'),
          })}
        </title>
      </Helmet>
      <Container>
        <ThemeProvider theme={appTheme}>{routes}</ThemeProvider>
      </Container>
    </>
  );
};

App.propTypes = {
  route: PropTypes.any,
  setProperty: PropTypes.any,
  theme: PropTypes.shape({
    name: PropTypes.oneOf(THEMES_LIST),
    brand: PropTypes.oneOf(THEMES_LIST),
  }),
};

export default App;
