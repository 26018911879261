import {
  API_BASE_URL,
  API_PATH_VISUALIZATION_CONTEXT,
} from '@/shared/constants/api';
import { VisualizationContext } from '@/shared/interfaces/visualization';
import { handleAccess } from '@/shared/utils/api-utils';

export function getVisualizationContext(): Promise<VisualizationContext> {
  return fetch(API_BASE_URL + API_PATH_VISUALIZATION_CONTEXT, {
    credentials: 'include',
  })
    .then(handleAccess)
    .then((res: Response) => {
      if (!res.ok) {
        throw res;
      }
      return res;
    })
    .then((res) => res.json());
}
