import { ROUTES } from '@/shared/constants/navigation';
import loadable from '@loadable/component';
import { DataPipelinePageVariation } from '@/shared/constants/dataPipelines';
import { fallback } from '@/routes/index';

// prettier-ignore
export default [
  {
    path: ROUTES.DATA_PIPELINE_DATA_CATALOG_DETAILS.path,
    component: loadable(ROUTES.DATA_PIPELINE_DATA_CATALOG_DETAILS.component, { fallback }),
    group: DataPipelinePageVariation.DATASET,
    routes: [
      {
        path: ROUTES.DATA_PIPELINE_DETAILS_SHARE_DATA.path,
        component: loadable(ROUTES.DATA_PIPELINE_DETAILS_SHARE_DATA.component, { fallback }),
        group: DataPipelinePageVariation.DATASET,
        modal: {
          enabled: true,
          width: '81.5%',
        },
      },
      {
        path: ROUTES.DATA_PIPELINE_DETAILS_DOWNLOAD_DATA.path,
        component: loadable(ROUTES.DATA_PIPELINE_DETAILS_DOWNLOAD_DATA.component, { fallback }),
        group: DataPipelinePageVariation.DATASET,
        modal: {
          enabled: true,
          width: '81.5%',
        },
      },
      {
        path: ROUTES.DATA_PIPELINE_DETAILS_EDIT.path,
        component: loadable(ROUTES.DATA_PIPELINE_DETAILS_EDIT.component, { fallback }),
        group: DataPipelinePageVariation.DATASET,
        modal: {
          enabled: true,
          width: '81.5%',
        },
      },
      {
        path: ROUTES.DATA_PIPELINE_DETAILS_SQL_EDITOR.path,
        component: loadable(ROUTES.DATA_PIPELINE_DETAILS_SQL_EDITOR.component, { fallback }),
        group: DataPipelinePageVariation.DATASET,
        modal: {
          enabled: true,
          width: '100%'
        }
      },
      {
        path: ROUTES.DATA_PIPELINE_DETAILS_EXTRA_CREATE.path,
        component: loadable(ROUTES.DATA_PIPELINE_DETAILS_EXTRA_CREATE.component, { fallback }),
        group: DataPipelinePageVariation.DATASET,
        modal: {
          enabled: true,
          width: '81.5%',
          callbackURL: ROUTES.DATA_PIPELINE_DATA_CATALOG_DETAILS.path,
        },
      },
      {
        path: ROUTES.DATA_PIPELINE_DETAILS_EXTRA_EDIT.path,
        component: loadable(ROUTES.DATA_PIPELINE_DETAILS_EXTRA_EDIT.component, { fallback }),
        group: DataPipelinePageVariation.DATASET,
        modal: {
          enabled: true,
          width: '81.5%',
          callbackURL: ROUTES.DATA_PIPELINE_DATA_CATALOG_DETAILS.path,
        },
      },
    ],
  },
  {
    path: ROUTES.DATA_PIPELINE_DATA_CATALOG.path,
    component: loadable(ROUTES.DATA_PIPELINE_DATA_CATALOG.component, { fallback }),
    group: DataPipelinePageVariation.DATASET,
    exact: true,
    routes: [
      {
        path: ROUTES.DATA_PIPELINE_PUBLIC_DATASET_DETAILS.path,
        component: loadable(ROUTES.DATA_PIPELINE_PUBLIC_DATASET_DETAILS.component, { fallback }),
        modal: {
          enabled: true,
          width: '81%',
          callbackURL: ROUTES.DATA_PIPELINE_PUBLIC_DATASETS.path,
        },
      },
      {
        path: ROUTES.DATA_PIPELINE_PUBLIC_DATASETS.path,
        component: loadable(ROUTES.DATA_PIPELINE_PUBLIC_DATASETS.component, { fallback }),
        group: DataPipelinePageVariation.DATASET,
        modal: {
          enabled: true,
          width: '78%',
          callbackURL: ROUTES.DATA_PIPELINE_DATA_CATALOG.path,
        },
      },
      {
        path: ROUTES.DATA_PIPELINE_DATA_FORM.path,
        component: loadable(ROUTES.DATA_PIPELINE_DATA_FORM.component, { fallback }),
        group: DataPipelinePageVariation.DATASET,
        modal: {
          enabled: true,
          width: '81%',
          callbackURL: ROUTES.DATA_PIPELINE_DATA_CONNECT.path,
        },
      },
      {
        path: ROUTES.DATA_PIPELINE_DATA_CONNECT.path,
        component: loadable(ROUTES.DATA_PIPELINE_DATA_CONNECT.component, { fallback }),
        group: DataPipelinePageVariation.DATASET,
        modal: {
          enabled: true,
          width: '81%',
        },
      },
    ],
  },
];
