import { ROUTES } from '@/shared/constants/navigation';

export const getHeaderNavigation = ({ t }: any) => ({
  Profile: {
    label: t('common:components.header.navigation.profile'),
  },
  help: {
    label: t('common:components.header.navigation.help'),
    to: ROUTES.ROOT.path,
  },
  signOut: {
    label: t('common:components.header.navigation.signOut'),
    to: ROUTES.ROOT.path,
  },
});
